import { MenuEntry } from '@neverswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: 'https://neverswap.com/#/',
  },
  // {
  //   label: t('Trade'),
  //   icon: 'TradeIcon',
  //   items: [
  //     {
  //       label: t('Exchange'),
  //       href: 'https://exchange.pancakeswap.finance/#/swap',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: 'https://exchange.pancakeswap.finance/#/pool',
  //     },
  //     {
  //       label: t('LP Migration'),
  //       href: 'https://v1exchange.pancakeswap.finance/#/migrate',
  //     },
  //   ],
  // },
  // {
  //   label: t('Farms'),
  //   icon: 'FarmIcon',
  //   href: '/farms',
  // },
  // {
  //   label: t('Pools'),
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  {
    label: t('Buy Eve'),
    icon: 'TradeIcon',
    href: 'https://neverswap.com/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x48Ea7cBabc983E4D0d67B8b2578B5eA665f40DFB',
  },
  {
    label: t('Prediction (BETA)'),
    icon: 'PredictionsIcon',
    href: '/prediction',
  },
  {
    label: t('Lottery'),
    icon: 'TicketIcon',
    href: '/',
    status: {
      text: t('Soon').toLocaleUpperCase(),
      color: 'success',
    },
  },
  {
    label: t('Vote'),
    icon: 'NftIcon',
    href: '/',
    status: {
      text: t('Soon').toLocaleUpperCase(),
      color: 'success',
    },
  },
  // {
  //   label: t('Collectibles'),
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: t('Team Battle'),
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  // },
  // {
  //   label: t('Teams & Profile'),
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       label: t('Task Center'),
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: t('Your Profile'),
  //       href: '/profile',
  //     },
  //   ],
  // },
  // {
  //   label: t('Info'),
  //   icon: 'InfoIcon',
  //   href: 'https://pancakeswap.info',
  // },
  // {
  //   label: t('IFO'),
  //   icon: 'IfoIcon',
  //   href: '/ifo',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Info'),
        href: 'https://info.neverswap.com/home',
      },
      {
        label: t('Docs'),
        href: 'https://docs.neverswap.com/',
      }
    ],
  },
]

export default config
